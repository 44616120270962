<template>
  <div>
    <b-overlay :variant="skin" :show="isloading" spinner-variant="primary" spinner-type="grow">
      <b-embed
        type="iframe"
        aspect="16by9"
        v-if="this.iframeUrl"
        :src="getDashboard(appConfig.layout.skin)"
        allowfullscreen
      />
    </b-overlay>
  </div>
</template>
<script>
import { BEmbed } from "bootstrap-vue";
import useAppConfig from "@core/app-config/useAppConfig";
import jwt from "jsonwebtoken";
import { mapState } from "vuex";
var METABASE_SITE_URL = "https://mb-at.estrategiaeducacional.com.br";
var METABASE_SECRET_KEY =
  "66bc8f1e8f481928a5d6c131bd2de864adee8b91c4a0c3c62a436b695b6322f0";

import _authService from "@/services/auth-service";
export default {
  setup() {
    const { skin } = useAppConfig();
    return {
      skin,
    };
  },
  components: {
    BEmbed,
  },
  computed: {
    ...mapState(["appConfig"]),
  },
  data() {
    return {
      iframeUrl: "",
      isloading: false,
      paramsUser: {},
    };
  },
  created() {
    this.getUser();
  },
  methods: {
    getUser() {
      this.isloading = true;
      _authService
        .getAuthUser()
        .then((res) => {
          this.paramsUser = {
            nome: res.content.email,
            meta: res.content.goal.toString(),
          };
          this.getDashboard();
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => (this.isloading = false));
    },
    getDashboard(skin) {
      var payload = {
        resource: { dashboard: 16 },
        params: this.paramsUser,
        exp: Math.round(Date.now() / 1000) + 120 * 60, // 10 minute expiration
      };

      var token = jwt.sign(payload, METABASE_SECRET_KEY);
      var theme = skin === "dark" ? "&theme=night" : "";
      this.iframeUrl =
        METABASE_SITE_URL +
        "/embed/dashboard/" +
        token +
        "#bordered=false&titled=false" +
        theme;

      return this.iframeUrl;
    },
  },
};
</script>
